import React from "react"



class WeProvide extends React.Component {

  render() {
    return (

      <section className="usr-admin-tab benifor nftstak mb-0">
          <div className="gray-bg">
          <div className="container"> 
            <h2 className="heading-h2 text-center">Our NFT Staking Platform <span className="bluecolor">Development Process</span></h2>
            <p className="text-center">
            Coinsclone follows an exhilarating step-by-step process for creating your NFT Staking Platform. Gain an insider’s perspective and forge ahead together.
            </p>
            <div className="row">
                  <div className="d-lg-flex flex-wrap add-one">
                    <div className="square" >
                      <h3>Ideation</h3>
                      <p className="pharagraph">
                      We begin by conducting a one-on-one consultation session with you to thoroughly understand your objectives. At Coinsclone, we prioritize our client's needs and business requirements. Thus target audience, end goals,  market landscape, and every other requirement gets noted here.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Conceptualization</h3>
                      <p className="pharagraph">
                      After gathering the requirements, we identify the key pain points and possibilities to lay a solid foundation for your custom NFT Staking Platform development. We create a rough draft by brainstorming innovative concepts and features that will set your NFT Staking Platform apart.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Designing</h3>
                      <p className="pharagraph">
                      With the blueprint, our expert UI/UX designers will start with the design process. Our designers will craft a stunning interface with advanced frameworks in this phase. We ensure that every aspect of your platform aligns seamlessly with brand identity and global trends.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Development</h3>
                      <p className="pharagraph">
                      This phase is the significant step in your NFT Staking Platform development, where our developers get design approval from clients. Leveraging the latest advancements, we build the staking website by integrating necessary smart contracts, features, and security mechanisms to ensure efficient functioning. 
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Testing</h3>
                      <p className="pharagraph">
                      In this testing phase, our testers and quality analysts will frequently test your DeFi lending and borrowing platform. We ensure the platform's security and seamless functioning, search for bugs or glitches, and resolve in case of any.
                      </p>
                    </div>
                    <div className="square" >
                      <h3>Deployment</h3>
                      <p className="pharagraph">
                      Here, our developers will carefully analyze the market trends. Understanding the client’s business needs, we will carefully launch the platform on an efficient network that has an impact on attracting users.
                      </p>
                    </div>
                  </div>
            </div>
          </div>
          </div>
      </section>
    )
  }
}

export default WeProvide
